interface teamDataProps {
    title: string,
    name: string,
    img: string,
}

const teamData : teamDataProps[] = [
    {
        title: "CEO",
        name: "Victor Etukinyang",
        img: "ceo.png"
    },
    // {
    //     title: "Director of IT",
    //     name: "Emmanuel Akpan",
    //     img: ""
    // },
    // {
    //     title: "Director of Finance",
    //     name: "Nathaniel Jimmy",
    //     img: ""
    // },
    // {
    //     title: "Software Dev",
    //     name: "Victor Otubure",
    //     img: ""
    // },
    // {
    //     title: "Social Media",
    //     name: "Eniola Kwuelum",
    //     img: ""
    // },
    // {
    //     title: "Sotware Dev",
    //     name: "Wale Onafeso",
    //     img: ""
    // },
    // {
    //     title: "Marketing Manager",
    //     name: "Nnamani Kelvin O.",
    //     img: ""
    // },
    // {
    //     title: "Mangement Sec",
    //     name: "Jessica",
    //     img: ""
    // },
]

export default teamData;