interface serviceDataProps {
    img: string,
    title: string,
    desc: string,
}


const serviceData : serviceDataProps[] = [
    {
        img: "./assets/img/service/happy.avif",
        title: "Health",
        desc: "Comprehensive health support for your well-being."
    },
    {
        img: "./assets/img/service/wellness.avif",
        title: "Wellness",
        desc: "Balanced and healthy lifestyle programs"
    },
    {
        img: "./assets/img/service/home.avif",
        title: "Real Estate",
        desc: "Real estate solutions for buying, selling, and investing"
    },
    {
        img: "./assets/img/service/education.avif",
        title: "Education",
        desc: "Educational programs and resources for learning and development"
    },
    {
        img: "./assets/img/service/tech.avif",
        title: "Technology",
        desc: "Innovative tech services and solutions for the digital age."
    },
]

export default serviceData;