interface privacePolicyDataProps {
    name: string,
    content: string,
}


const privacyPolicyData : privacePolicyDataProps[] = [
    {
        name: `INTRODUCTION:`,
        content: `At Igoglobal International Inc. ("Igoglobal," "we," "us," or "our"), we use cookies and similar technologies to provide, protect, and improve our services. This policy explains how we use cookies and the choices you have regarding their use.`,

    },
    {
        name: `WHAT ARE COOKIES?:`,
        content: `Cookies are small pieces of text used to store information on web browsers. They help us personalize content, tailor and measure ads, and provide a safer experience. We use cookies for authentication, security, advertising, site features, performance, analytics, and more.`,

    },
    {
        name: `TYPES OF COOKIES WE USE:`,
        content: `- Session Cookies: These are deleted when you close your browser. \n- Persistent Cookies: These stay on your browser until they expire or you delete them.`,

    },
    {
        name: `HOW WE USE COOKIES:`,
        content: `- Authentication: To verify your account and determine when you’re logged in.
        \n- Security: To keep your account, data, and our services safe and secure.
        \n- Advertising: To show ads and make recommendations for businesses and other organizations.
        \n- Site Features: To enable functionality that helps us provide our services.
        \n- Performance: To provide you with the best experience possible.
        \n- Analytics: To better understand how people use our services.
        `,

    },
    {
        name: `WHERE WE USE COOKIES:`,
        content: `- On our website and apps.
        \n- On websites and apps provided by other companies that use our services.`
    },
    {
        name: `THIRD-PARY COOKIES:`,
        content: `Other parties may also use cookies on our services to provide services to us and our partners. This includes measurement partners who help advertisers understand the effectiveness of their advertising campaigns.`
    },
    {
        name: `YOUR CHOICE CONCERNING COOKIES:`,
        content: `Your browser or device may offer settings that allow you to choose whether browser cookies are set and to delete them. However, certain parts of our services may not work properly if you have disabled browser cookie use.`
    },
    {
        name: `CHANGES TO THIS POLICY:`,
        content: `We may update this policy from time to time, so please check back for updates.This Cookies Policy applies to our services. By using our services, you agree to the use of cookies as described in this policy.`
    },
]

export default privacyPolicyData;