import React from 'react';

function FooterAddress () {
    return (
        <div className="col-md-4">
            <div className="widget">
                <h2 className="widget-title">Our address</h2>
                <div className="pbmit-contact-widget-lines">
                    1, Morenikeji Street, Off Ladipo Kasimu Street, Allen Avenue, Ikeja <br></br> Lagos State, Nigeria
                </div>
            </div>
        </div>
    )
}

export default FooterAddress;