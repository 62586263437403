interface swiperDataProps {
    img: string, 
    minTitle: string, title: string, subTitle: string,
    text: string, subText: string
}

const swiperData : swiperDataProps[] = [
    {
        img: "./assets/img/slider/slider-4.avif",
        minTitle: "Meet us, in-person",
        title: "Visit us",
        subTitle: "Ikeja-Lagos",
        text: "go to the",
        subText: "contact page for more"           
    },
    {
        img: "/assets/img/slider/slider-3.avif",
        minTitle: "Change your world",
        title: "Small",
        subTitle: "impacts",
        text: "Take charge",
        subText: "and redefine your health"
    },
    {
        img: "/assets/img/slider/slider-1.avif",
        minTitle: "automated communication",
        title: "Igo",
        subTitle: "chatbot",
        text: "Have questions?",
        subText: "kindly talk to our igobot"
    },
]

export default swiperData;