import React from 'react';

function FooterTitle () {
    return (
        <>
            <div className="col-xl-4 col-lg-3 col-md-6">								
                <div className="pbmit-footer-logo">
                    <img src="/assets/img/igoglobal.png" className="img-fluid" alt="igoglobal logo"></img>
                </div>                      
            </div>			
            <div className="pbmit-footer-left col-xl-4 col-lg-5 col-md-6">
                <h3>Subscribe to our newsletter! Stay always in touch!</h3>
            </div>	
        </>
    )
}

export default FooterTitle;