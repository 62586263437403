interface TestimonialDataProps {
    img: string, 
    username: string,
    clientType: string,
    comment: string,
}

const TestimonialData : TestimonialDataProps[] = [
    {
        img: "user-5.avif",
        username: "Ogboni",
        clientType: "happy customer",
        comment: "I dreaded my period due to severe symptoms, but after trying Iroko drink, these symptoms disappeared despite other medications failing",
    },
    {
        img: "user-4.avif",
        username: "Jordan",
        clientType: "software developer",
        comment: "Using pure virgin has revitalized my marriage again, my husband and I live as though we are in our youthful age and the experience is epic",
    },
    {
        img: "user-6.avif",
        username: "Tomiwa",
        clientType: "programmer",
        comment: "Iroko drink has helped me alot as regards my waist pain which had persistently troubled me and reduced my sports activities due",
    },
    {
        img: "user-1.avif",
        username: "Nathaly",
        clientType: "graphics and UI designer",
        comment: "For me, Iroko worked wonders on a persistent toothache that didn't improve with other toothpaste, and now the pain has subsided",
    },
    {
        img: "user-3.avif",
        username: "Adedeji",
        clientType: "Machine Learning Specialist",
        comment: "I have had intense digestive system issues for quite a while now, but ever since I started using Iroko drink, my apetite had been way different",
    },
    {
        img: "user-2.avif",
        username: "James",
        clientType: "Vendor",
        comment: "Moving from place to place has not been easy for me, but after being introduced to Iroko, moving around has been more fun for me",
    },
]

export default TestimonialData;